<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche type de produit #{{type.id}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="success" size="sm" class="btn-icon mr-1" @click="save"><i class="icon-md fas fa-save"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-form-group label="Titre:">
            <b-form-input v-model="type.titre" type="text" required placeholder="Entre le titre"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label>Statut:</label>
            <select class="form-control" v-model="type.status">
              <option v-for="(s,i) in parametrage.status" :value="s.id" :key="i">{{s.titre}}</option>
            </select>
          </div>
        </b-form>
        <b-row>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Question</th>
                <th scope="col">Type</th>
                <th scope="col">Reponse</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <b-form-input v-model="qestionTmp.request" type="text" placeholder="Votre question"></b-form-input>
                </th>
                <th>
                  <select class="form-control" v-model="qestionTmp.type" @change="removeRes(qestionTmp)">
                    <option value="libre">Libre</option>
                    <option value="choix">Choix</option>
                  </select>
                </th>
                <th>
                  <b-form-input v-for="(r,i) in qestionTmp.res" v-model="r.r" :key="i"></b-form-input>
                </th>
                <th>
                  <b-button variant="info" size="sm" class="btn-icon mr-1" @click="addRes(qestionTmp)" v-if="qestionTmp.type == 'choix'"><i class="icon-md fas fa-list"></i></b-button>
                  <b-button variant="success" size="sm" class="btn-icon mr-1" @click="addQuestion"><i class="icon-md fas fa-plus"></i></b-button>
                </th>
              </tr>
              <tr v-for="(q,i) in type.questions" :key="i">
                <th>
                  <b-form-input v-model="q.request" type="text" placeholder="Votre question"></b-form-input>
                </th>
                <th>
                  <select class="form-control" v-model="q.type" @change="removeRes(q)">
                    <option value="libre">Libre</option>
                    <option value="choix">Choix</option>
                  </select>
                </th>
                <th>
                  <b-form-input v-for="(r,i) in q.res" v-model="r.r" :key="i"></b-form-input>
                </th>
                <th>
                  <b-button variant="info" size="sm" class="btn-icon mr-1" @click="addRes(q)" v-if="q.type == 'choix'"><i class="icon-md fas fa-list"></i></b-button>
                  <b-button variant="danger" size="sm" class="btn-icon mr-1" @click="removeQuestion(i)"><i class="icon-md fas fa-trash"></i></b-button>
                </th>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      type:{
        id:null,
        titre:null,
        questions:[],
        status:1
      },
      qestionTmp:{
        request:null,
        answer:null,
        type:'libre',
        res:[]
      },
      parametrage:{
        status:[]
      },
    };
  },
  components: {
  },
  computed: {
  },
  methods: {
    save() {
      this.$store.back.ajax('/parametrage/update?module=ProduitType', this.type, (res) => {
        if(res.status === true){
          this.type = res.data;
          this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    addQuestion(){
      if(this.qestionTmp.request === ""){
        return false;
      }
      this.type.questions.push(this.qestionTmp);
      this.qestionTmp = {
        request:null,
        answer:null,
        type:'libre',
        res:[]
      };
    },
    removeQuestion(i){
      this.type.questions.splice(i, 1);
    },
    addRes(model){
      if(!model.res){
        model.res = [];
      }
      model.res.push({r:""});
      console.log(model)
    },
    removeRes(model){
      model.res = [];
    }
  },
  watch: {
  },
  beforeMount(){
    let params = {
      modules: ["LeadStatus"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.status = res.data['status'];
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);

    this.$store.back.ajax(`/parametrage/${this.$route.params.id}?module=ProduitType`, null, (res) => {
      if(res.status === true){
        this.type = res.data.model;
      }
    });
  }
};
</script>
